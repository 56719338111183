import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

import { ELIGIBLE_EMPLOYEES_SEARCH } from "components/Search/gql";
import { EligibleEmployee } from "generated/types";

export const useEligibleEmployeesSearch = (data: EligibleEmployee[]) => {
  const client = useApolloClient();

  const loadOptions = async (value: string) => {
    try {
      const { data } = await client.query({
        query: ELIGIBLE_EMPLOYEES_SEARCH,
        variables: {
          search: value,
        },
      });

      if (data?.searchEligibleEmployees?.data) {
        return data.searchEligibleEmployees.data.map(
          ({ id, firstName, lastName, business, status }: EligibleEmployee) => {
            return {
              value: id,
              label: `${firstName} ${lastName}`,
              member: business?.data?.name,
              name: `${firstName} ${lastName}`,
              status: status,
            };
          }
        );
      }
    } catch (error) {
      toast.error((error as Error).message);
    }

    return [];
  };
  const searchPillNames = data?.reduce(
    (obj, { id, firstName, lastName }) => ({
      ...obj,
      [id!]: `${firstName} ${lastName}`,
    }),
    {}
  ) as { [key: string]: string };

  return {
    loadOptions,
    searchPillNames,
  };
};
